import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Videos = () => (
  <Layout pageTitle="Videos">
    <SEO title="Videos" />
    <div className="row">
      <div className="col-md-6">
        <h2>"The Alamo," Clio The Muse</h2>
        <p>In 2022 I spoke with Raffi Andonian on his YouTube TV channel, Clio the Muse, about my new book, Unsettled Land, and the role that the Alamo has played in the state’s historical memory. Check out the full video <a href="https://watch.zondratv.com/program-group/720b0a3b480ff5a0eb739c7d27045b22/program/a5e8417912ca090421012c231ae6906f" target="_blank">here</a>.</p>
      </div>
      <div className="col-md-6">
        <iframe title="The Alamo - Clio the Muse (clip) - Season 2 - Episode 3 (preview)" width="100%" height="315" src="https://www.youtube.com/embed/0OUOU13DxLA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      <hr />
      <div className="col-md-6">
        <h2>Hutchins Lecture</h2>
        <p>In 2016, I was invited to deliver a Hutchins Lecture at the Center for the Study of the American South at the University of North Carolina, Chapel Hill. My talk was entitled “Unbecoming Southern: The Roots of Texas Exceptionalism.”</p>
      </div>
      <div className="col-md-6">
        <iframe title="Hutchins Lecture" width="100%" height="315" src="https://www.youtube.com/embed/7zeqLJAeSRw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      <hr />
      <div className="col-md-6">
        <h2>Adventures of Sam and Ripley</h2>
        <p>In 2016, I developed my Texas History to 1850, HIST 3363, as an on-line course. I spent some of my time driving around the state, filming short video clips at historic sites with my dog, Ripley. Check out the “exciting” teaser!</p>
      </div>
      <div className="col-md-6">
        <iframe title="Adventures of Sam and Ripley" width="100%" height="315" src="https://www.youtube.com/embed/Shfb-ZuTCis" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      <hr />
      <div className="col-md-6">
        <h2>Texas to 1850 Course Introduction</h2>
        <p>Here’s the course introduction to my online Texas History course. To my surprise, Ripley proved to be remarkably well-behaved.</p>
      </div>
      <div className="col-md-6">
        <iframe title="Texas to 1850 Course Introduction" width="100%" height="315" src="https://www.youtube.com/embed/1clqxd4yzCw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      <hr />
      <div className="col-md-6">
        <h2>"Ties between the American Revolution and the Texas Revolution," San Jacinto Conference</h2>
        <p>In 2009 I gave my first talk talk to the San Jacinto Symposium in Houston on the connections between the American Revolution and the rebellion in Texas.</p>
      </div>
      <div className="col-md-6">
        <iframe title="San Jacinto Symposium" width="100%" height="315" src="https://www.youtube.com/embed/cMHnj3IDBs4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      <hr />
      {/* <div className="col">
        <h2>Border Land talk <span class="badge badge-dark">Coming soon</span></h2>
        <p>Here’s a talk I gave with Digital Creation director Ramona Holmes at UT Arlington in January, 2017, to introduce the new “Border Land” website, which maps sites of interethnic violence in early nineteenth century Texas.</p>
      </div> */}
      {/* <div className="col-md-6">
      <iframe title="Border Land talk" width="100%" height="315" src="https://www.youtube.com/embed/1clqxd4yzCw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div> */}
    </div>
  </Layout>
)

export default Videos
